import { useContext, useState } from "react";
import { NavigationContext } from "@/contexts/NavigationContext";
import { useSSRLayoutEffect, mq } from "@oskarengstrom/sage-ui";
import styled from "@emotion/styled";
import Layout from "@/components/Layout";
import { createClient, cleanCMSData } from "prismic-configuration";
import { Global, css } from "@emotion/react";
import Intro from "@/components/Intro";
import HomeView from "@/components/HomeView";

export default function Homepage({ page, navData, global }) {
  const [showIntro, setShowIntro] = useState(true);
  const { transitionType } = useContext(NavigationContext);
  useSSRLayoutEffect(() => {
    if (transitionType === "back") {
      setShowIntro(false);
    }
  }, [transitionType]);

  return (
    <>
      <Global
        styles={css`
          html {
            overflow: hidden;
            ${mq.only.touchDevice} {
              overflow: initial;
            }
          }
        `}
      />
      <LayoutStyled navData={navData} global={global} page={page}>
        {showIntro ? (
          <Intro page={page} setShowIntro={setShowIntro} />
        ) : (
          <HomeView page={page} />
        )}
      </LayoutStyled>
    </>
  );
}

const LayoutStyled = styled(Layout)`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  ${mq.only.touchDevice} {
    overflow: initial;
    width: 100%;
    height: 100%;
  }
`;

export async function getStaticProps({ preview = false, previewData }) {
  const client = createClient({ previewData, req: null });

  const doc = await client.getSingle("homepage", {});
  const navData = await client.getSingle("navigation", {});
  const global = await client.getSingle("global_meta", {});

  if (!doc || !doc?.data) return { notFound: true };
  if (!navData || !navData?.data) return { notFound: true };

  return {
    props: {
      page: cleanCMSData(doc.data),
      navData: cleanCMSData(navData),
      global: cleanCMSData(global.data),
    },
  };
}
