export function blobX(blob, containerWidth, scale) {
  return (x, align = "center") => {
    let offset = 0.5;
    if (align === "left") {
      offset = 0;
    } else if (align === "right") {
      offset = 1;
    }
    return `${containerWidth * x - blob.blobwidth * scale * offset}px`;
  };
}

export function blobY(blob, containerHeight, scale) {
  return (y, align = "center") => {
    let offset = 0.5;
    if (align === "top") {
      offset = 0;
    } else if (align === "bottom") {
      offset = 1;
    }
    return `${containerHeight * y - blob.blobheight * scale * offset}px`;
  };
}
