import { Box } from "@oskarengstrom/sage-ui";
import { Canvas } from "@/components/Canvas";
import CanvasSectionV2 from "@/slices/CanvasSectionV2";
import CanvasVideo from "@/slices/CanvasVideo";
import { SliceZone } from "@prismicio/react";
import useIsTouchDevice from "@/hooks/useIsTouchDevice";

const HomeView = ({ page }) => {
  const isTouchDevice = useIsTouchDevice();

  if (typeof isTouchDevice === "undefined") {
    return <div></div>;
  }

  if (isTouchDevice) {
    return (
      <Box py={[6]}>
        <SliceZone
          slices={page.slices}
          components={{
            canvas_sectionv2: CanvasSectionV2,
            canvas_video: CanvasVideo,
          }}
        />
      </Box>
    );
  }

  return (
    <Canvas>
      <SliceZone
        slices={page.slices}
        components={{
          canvas_sectionv2: CanvasSectionV2,
          canvas_video: CanvasVideo,
        }}
      />
    </Canvas>
  );
};

export default HomeView;
