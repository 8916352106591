import { useContext } from "react";
import { NavigationContext } from "@/contexts/NavigationContext";
import { gsap } from "gsap";
import styled from "@emotion/styled";
import { useBrowserLayoutEffect } from "@/utils/useBrowserLayoutEffect";
import { mq } from "@oskarengstrom/sage-ui";
import useIsTouchDevice from "@/hooks/useIsTouchDevice";

export const Canvas = ({ size, children }) => {
  const isTouchDevice = useIsTouchDevice();
  const { status } = useContext(NavigationContext);

  function throttle(func, wait) {
    let context,
      args,
      timeout,
      previous = 0;

    const later = function () {
      previous = Date.now();
      timeout = null;
      func.apply(context, args);
    };

    return function () {
      const now = Date.now();
      const remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
        }
        previous = now;
        func.apply(context, args);
      } else if (!timeout) {
        timeout = setTimeout(later, remaining);
      }
    };
  }

  useBrowserLayoutEffect(() => {
    if (status === "transitioning") return;

    let holder = document.querySelector(".holder"),
      overflowX,
      mapPositionX,
      overflowY,
      mapPositionY,
      lastX,
      lastY;

    let isDragging = false;

    function onResize(e) {
      overflowX = holder.offsetWidth - window.innerWidth;
      mapPositionX = gsap.utils.mapRange(
        0,
        window.innerWidth,
        overflowX / 2,
        overflowX / -2
      );
      overflowY = holder.offsetHeight - window.innerHeight;
      mapPositionY = gsap.utils.mapRange(
        0,
        window.innerHeight,
        overflowY / 2,
        overflowY / -2
      );
    }

    function onMouseMove(e) {
      if (isTouchDevice) return;
      if (overflowX > 0 || overflowY > 0) {
        let x =
          e.clientX || (e.changedTouches && e.changedTouches[0].clientX) || 0;
        let y =
          e.clientY || (e.changedTouches && e.changedTouches[0].clientY) || 0;
        gsap.to(holder, {
          duration: 0.9,
          overwrite: true,
          ease: "power3",
          x: mapPositionX(x),
          y: mapPositionY(y),
        });
      }
    }

    const throttledMouseMove = throttle(onMouseMove, 50);

    function onTouchStart(e) {
      isDragging = true;
      lastX = e.touches[0].clientX;
      lastY = e.touches[0].clientY;
    }

    const maxX = 920;
    const maxY = 500;

    function onTouchMove(e) {
      if (isDragging) {
        const newX = lastX - e.touches[0].clientX;
        const newY = lastY - e.touches[0].clientY;

        const currentX = gsap.getProperty(holder, "x");
        const currentY = gsap.getProperty(holder, "y");

        let x = currentX - newX * 15;
        let y = currentY - newY * 15;

        if (x > maxX) x = maxX;
        if (x < -maxX) x = -maxX;
        if (y > maxY) y = maxY;
        if (y < -maxY) y = -maxY;

        gsap.to(holder, {
          duration: 1,
          overwrite: true,
          ease: "power3.out",
          x,
          y,
        });
        lastX = e.touches[0].clientX;
        lastY = e.touches[0].clientY;
      }
    }

    const throttledTouchMove = throttle(onTouchMove, 10);

    function onTouchEnd(e) {
      isDragging = false;
    }

    const onMouseLeave = () => {
      setTimeout(() => {
        gsap.to(holder, {
          duration: 5,
          overwrite: true,
          ease: "power4.inOut",
          x: 0,
          y: 0,
        });
      }, 1000);
    };

    window.addEventListener("resize", onResize);

    if (isTouchDevice) {
      holder.addEventListener("touchstart", onTouchStart);
      holder.addEventListener("touchmove", throttledTouchMove);
      holder.addEventListener("touchend", onTouchEnd);
      holder.removeEventListener("mousemove", throttledMouseMove);
      holder.removeEventListener("mouseleave", onMouseLeave);
    } else {
      holder.addEventListener("mousemove", throttledMouseMove);
      holder.addEventListener("mouseleave", onMouseLeave);
      holder.addEventListener("touchstart", onTouchStart);
      holder.addEventListener("touchmove", throttledTouchMove);
      holder.addEventListener("touchend", onTouchEnd);
    }
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
      holder.removeEventListener("mousemove", throttledMouseMove);
      holder.removeEventListener("mouseleave", onMouseLeave);
      holder.removeEventListener("touchstart", onTouchStart);
      holder.removeEventListener("touchmove", throttledTouchMove);
      holder.removeEventListener("touchend", onTouchEnd);
    };
  }, [isTouchDevice, status]);

  return (
    <HomeViewport className="wrapper">
      <Container>
        <CanvasStyled size={size} className="holder">
          {children}
        </CanvasStyled>
      </Container>
    </HomeViewport>
  );
};

const HomeViewport = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  ${mq.only.computer} {
    overflow: hidden;
    pointer-events: auto;
  }
  ${mq.only.touchDevice} {
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
`;

const CanvasStyled = styled.div`
  --canvasWidth: ${(props) => props.size || "3232px"};
  --canvasHeight: calc(var(--canvasWidth) * 0.71);

  height: var(--canvasHeight);
  width: var(--canvasWidth);

  will-change: transform;

  ${mq.only.md} {
    --canvasWidth: 3000px;
  }
  ${mq.only.touchDevice} {
    will-change: unset;
  }
`;
