import { mq } from "@oskarengstrom/sage-ui";
import { blobX, blobY } from "@/utils/blobPositionV2";
import getSizeFromPath from "@/utils/getSizeFromPath";

export default function generateStyles(scope) {
  const { containerWidth, containerHeight, blobs, theme } = scope;

  const mainBlob = blobs.find((b) => b.name === "main-blob");
  const smallBlob = blobs.find((b) => b.name === "small-blob");
  const sideBlob = blobs.find((b) => b.name === "side-blob");

  const mainBlobScale = (containerWidth / mainBlob.width) * 1;
  const mainBlobScaleLr = (containerWidth / mainBlob.width) * 1.5;
  const mainBlobScaleMobile = (containerWidth / mainBlob.width) * 3;
  const smallBlobScale = 1;
  const sideBlobScale = 1;
  const sideBlobScaleMobile = 1;

  const mainBlobSize = getSizeFromPath(
    blobs.find((b) => b.name === "main-blob").path_data
  );

  const mainBlobX = blobX(
    {
      blobwidth: mainBlob.width,
      blobheight: mainBlob.height,
    },
    containerWidth,
    mainBlobScale
  );
  const mainBlobY = blobY(
    {
      blobwidth: mainBlob.width,
      blobheight: mainBlob.height,
    },
    containerHeight,
    mainBlobScale
  );
  const mainBlobXLr = blobX(
    {
      blobwidth: mainBlob.width,
      blobheight: mainBlob.height,
    },
    containerWidth,
    mainBlobScaleLr
  );
  const mainBlobYLr = blobY(
    {
      blobwidth: mainBlob.width,
      blobheight: mainBlob.height,
    },
    containerHeight,
    mainBlobScaleLr
  );

  const mainBlobYMobile = blobY(
    {
      blobwidth: mainBlob.width,
      blobheight: mainBlob.height,
    },
    containerHeight,
    mainBlobScaleMobile
  );
  const smallBlobX = blobX(
    {
      blobwidth: smallBlob.width,
      blobheight: smallBlob.height,
    },
    containerWidth,
    smallBlobScale
  );
  const smallBlobY = blobY(
    {
      blobwidth: smallBlob.width,
      blobheight: smallBlob.height,
    },
    containerHeight,
    smallBlobScale
  );
  const sideBlobX = blobX(
    {
      blobwidth: sideBlob.width,
      blobheight: sideBlob.height,
    },
    containerWidth,
    sideBlobScale
  );
  const sideBlobY = blobY(
    {
      blobwidth: sideBlob.width,
      blobheight: sideBlob.height,
    },
    containerHeight,
    sideBlobScale
  );

  const sideBlobXMobile = blobX(
    {
      blobwidth: sideBlob.width,
      blobheight: sideBlob.height,
    },
    containerWidth,
    sideBlobScaleMobile
  );

  const sideBlobYMobile = blobY(
    {
      blobwidth: sideBlob.width,
      blobheight: sideBlob.height,
    },
    containerHeight,
    sideBlobScaleMobile
  );

  return {
    "main-blob": {
      "--scale": mainBlobScale,
      "--x": mainBlobX(0.0, "left"),
      "--y": mainBlobY(1, "bottom"),
      background: theme.palette.background,
      [mq.down.lr({ theme })]: {
        "--scale": mainBlobScaleLr,
        "--x": mainBlobX(0.05, "left"),
        "--y": mainBlobY(0.85, "bottom"),
      },
      // [mq.down.md({ theme })]: {
      //   "--scale": mainBlobScaleMobile,
      //   "--y": mainBlobYMobile(0.6, "center"),
      // },
      [mq.down.sm({ theme })]: {
        "--scale": mainBlobScaleMobile,
        "--y": mainBlobYMobile(0.6, "center"),
      },
    },
    "small-blob": {
      "--scale": smallBlobScale,
      "--x": smallBlobX(0.18, "left"),
      "--y": smallBlobY(1, "bottom"),
      background: theme.constants.colors.alpha.lightgrey,
      [mq.down.lr({ theme })]: {
        "--x": smallBlobX(0.12, "left"),
      },
      [mq.down.md({ theme })]: {
        "--x": smallBlobX(-0.03, "left"),
        "--y": smallBlobY(1.15, "bottom"),
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    "small-blob-behind": {
      "--scale": smallBlobScale,
      "--x": smallBlobX(0.18, "left"),
      "--y": smallBlobY(1, "bottom"),
      background: theme.palette.primary.light,
      [mq.down.lr({ theme })]: {
        "--x": smallBlobX(0.12, "left"),
      },
      [mq.down.md({ theme })]: {
        "--x": smallBlobX(-0.03, "left"),
        "--y": smallBlobY(1.15, "bottom"),
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    "side-blob": {
      "--scale": sideBlobScale,
      "--x": sideBlobX(1, "right"),
      "--y": sideBlobY(0.5),
      background: theme.palette.primary.light,
      [mq.down.md({ theme })]: {
        "--x": sideBlobX(1.1, "right"),
        "--y": sideBlobY(0.7),
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    "side-blob-behind": {
      "--scale": sideBlobScale,
      "--x": sideBlobX(1, "right"),
      "--y": sideBlobY(0.5),
      background: theme.palette.primary.main,
      [mq.down.md({ theme })]: {
        "--x": sideBlobX(1.1, "right"),
        "--y": sideBlobY(0.7),
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    "side-blob-mobile": {
      "--scale": sideBlobScaleMobile,
      "--x": sideBlobXMobile(1.1, "right"),
      "--y": sideBlobYMobile(0.67),
      background: theme.palette.primary.light,
    },
    "side-blob-behind-mobile": {
      "--scale": sideBlobScaleMobile,
      "--x": sideBlobXMobile(1.1, "right"),
      "--y": sideBlobYMobile(0.67),
      background: theme.palette.primary.main,
    },
    woman: {
      right: "10%",
      transform: "translateX(50%)",
      bottom: 0,
      height: "max(70vh, 40vw)",
      width: "auto",
      [mq.down.md({ theme })]: {
        right: "-4%",
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    "woman-mobile": {
      top: "50%",
      right: "-13%",
      width: "25rem",
      transform: "translate(5%, -20rem)",
    },
    "woman-front-mobile": {
      width: "25rem",
      top: "50%",
      right: "-13%",
      transform: "translate(5%, -20rem)",
      clipPath: "inset(0% 0% 47% 0%)",
    },
    swimmer: {
      top: "2rem",
      left: "20%",
      width: "30vh",
      [mq.down.md({ theme })]: {
        display: "none",
      },
    },
    vd: {
      left: "calc(30% - 10vw)",
      bottom: "0%",
      width: "calc(200px + 10vw)",
      transform: "translate(5%,10%)",
      [mq.down.lr({ theme })]: {
        left: "calc(25% - 10vw)",
        width: "22rem",
      },
      [mq.down.md({ theme })]: {
        // left: "calc(35vw - 200px)",
        // width: "calc(200px + 10vw)",
        display: "none",
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    "vd-behind": {
      left: "calc(30% - 10vw)",
      bottom: "0%",
      width: "calc(200px + 10vw)",
      transform: "translate(5%,10%)",
      [mq.down.lr({ theme })]: {
        left: "calc(25% - 10vw)",
        width: "22rem",
      },
      [mq.down.md({ theme })]: {
        // left: "calc(35vw - 200px)",
        // width: "calc(200px + 10vw)",
        display: "none",
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
    roman: {
      left: "-0%",
      bottom: "0",
      transform: "translate(-40%, 15%)",
      width: "100vh",
      height: "100vh",
      [mq.down.lr({ theme })]: {
        width: "100vh",
        height: "100vh",
        left: "-0%",
      },
      [mq.down.md({ theme })]: {
        width: "100vh",
        height: "100vh",
        left: "3%",
        bottom: "-30%",
        transform: "translateX(-50%)",
      },
      [mq.down.sm({ theme })]: {
        display: "none",
      },
    },
  };
}
