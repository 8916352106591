import styled from "@emotion/styled";
import React from "react";
import hoverColor from "@/utils/hoverColor";
import { Box, Typography, useResponsive } from "@oskarengstrom/sage-ui";

const transition = "all 0.05s linear";

export default function ActionButton({
  color,
  icon = "downArrow",
  size,
  iconSize,
  ...restProps
}) {
  return (
    <Wrapper color={color} height={["52px"]} px={["24px"]} {...restProps}>
      <Typography color="white" variant="labelSmall">
        Välkommen in
      </Typography>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  border-radius: 100px;
  background-color: ${(props) =>
    props.color || props.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.constants.colors.white};

  &:hover {
    background-color: ${(props) =>
      hoverColor(props.color || props.theme.palette.primary.main)};
  }

  transition: ${transition};
`;
