import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "@emotion/styled";
import { themes } from "@/styles/theme";
import {
  useSSRLayoutEffect,
  Stack,
  SageProvider,
  mq,
  Box,
} from "@oskarengstrom/sage-ui";
import { css } from "@emotion/react";
import gsap, { Back } from "gsap";
import { GSDevTools } from "gsap/dist/GSDevTools";
import IntroTitle from "@/components/Intro/IntroTitle";
import renderLayoutTree from "@/utils/renderLayoutTree";
import ActionButton from "@/components/ui/ActionButton";
import RichText from "@/utils/RichText";
import useIsTouchDevice from "@/hooks/useIsTouchDevice";
import RenderLayer from "@/components/RenderLayer";
import { useSetRecoilState } from "recoil";
import { pointerState } from "@/contexts/pointer/pointerState";
import generateStyles from "./styles";

gsap.registerPlugin(GSDevTools);

export default function Intro({ page, setShowIntro }) {
  const isTouchDevice = useIsTouchDevice();
  const { images, title, description, blobs, graphics } = page;
  const ref = useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const setPointer = useSetRecoilState(pointerState);

  const [open, setOpen] = useState(true);
  const tl = useRef(
    gsap.timeline({
      paused: true,
      onComplete: () => setShowIntro(false),
    })
  );

  useEffect(() => {
    !open && tl.current.play();
  }, [open]);

  useEffect(() => {
    function onResize() {
      if (!ref.current) return;
      setContainerWidth(ref.current.offsetWidth);
      setContainerHeight(ref.current.offsetHeight);
    }
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref.current, isTouchDevice]);

  useSSRLayoutEffect(() => {
    const ease = "power2.in";
    const easeBack = Back.easeIn.config(0.4);

    // const easeBack = "power2.in";
    // const easeBackMore = Back.easeIn.config(1.9);
    const duration = 1.25;

    let ctx = gsap.context(() => {
      tl.current.to(
        "#intro_main-blob",
        {
          scale: "+=1",
          transformOrigin: "center",
          duration,
          ease,
        },
        0
      );
      tl.current.to(
        "#bottom-btn",
        {
          scale: "+=20",
          transformOrigin: "50% -100%",
          duration,
          ease,
        },
        0
      );

      tl.current.to(
        ["#intro_image_woman"],
        {
          scale: "+=7",
          transformOrigin: "-10% 35%",
          duration,
          ease,
        },
        0
      );

      tl.current.to(
        ["#intro_image_vd", "#intro_image_vd-behind"],
        {
          scale: "+=1.5",
          // autoAlpha: 0,
          transformOrigin: "130% -10%",
          duration,
          ease: easeBack,
        },
        0
      );
      tl.current.to(
        ["#intro_image_swimmer"],
        {
          scale: "+=0.4",
          // autoAlpha: 0,
          transformOrigin: "180% 200%",
          duration,
          ease: easeBack,
        },
        0
      );
      tl.current.to(
        ["#intro_small-blob", "#intro_small-blob-behind"],
        {
          scale: "+=1",
          // autoAlpha: 0,
          transformOrigin: "100% -5%",
          duration,
          ease: easeBack,
        },
        0
      );
      tl.current.to(
        ["#intro_side-blob", "#intro_side-blob-behind"],
        {
          scale: "+=1.5",
          // autoAlpha: 0,
          transformOrigin: "-150% 50%",
          duration,
          ease: easeBack,
        },
        0
      );
      tl.current.to(
        ["#intro-text-block"],
        {
          scale: "+=0.1",
          autoAlpha: 0,
          transformOrigin: "50% 50%",
          duration,
          ease: Back.easeIn.config(1),
        },
        0
      );

      tl.current.to(
        ["#intro-wrapper"],
        {
          autoAlpha: 0,
          duration: 0.8,
          ease: "linear",
        },
        "-=0.8"
      );
    });

    // let devTools = GSDevTools.create({
    //   animation: tl.current,
    //   // minimal: true,
    //   visibility: "auto",
    // });

    return () => {
      ctx.revert();
      // devTools.kill();
    };
  }, [isTouchDevice]);

  const styles = generateStyles({
    containerWidth,
    containerHeight,
    blobs,
    theme: themes.blue,
  });

  const tree = renderLayoutTree(
    images,
    blobs,
    graphics,
    isTouchDevice ? "mobile" : "desktop"
  );

  if (typeof isTouchDevice === "undefined") {
    return null;
  }

  return (
    <SageProvider theme={themes.blue} key={isTouchDevice}>
      <Wrapper ref={ref} id="intro-wrapper" dev>
        <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
          <defs>
            {blobs.map((blob) => (
              <ClipPath
                id={`intro_${blob.name}`}
                key={blob.name}
                config={styles[blob.name]}
              >
                <path d={blob.path_data} />
              </ClipPath>
            ))}
          </defs>
        </svg>
        <RenderLayer
          layer="back"
          tree={tree}
          section_id="intro"
          config={styles}
        />
        <RenderLayer
          layer="middle"
          tree={tree}
          section_id="intro"
          config={styles}
        />
        <RenderLayer
          layer="front"
          tree={tree}
          section_id="intro"
          config={styles}
        >
          <Stack
            id="intro-text-block"
            height="100%"
            alignItems="center"
            justifyContent="center"
            pt={["calc(-5rem + 35vh)", "0rem", "4rem"]}
            gap={2}
          >
            <IntroTitle
              css={(theme) =>
                css({
                  // width: "min(60vw, 45rem)",
                  width: "40vw",
                  height: "auto",
                  [mq.down.md({ theme })]: {
                    width: "min(80vw, 30rem)",
                  },
                })
              }
            />
            <h1
              style={{
                visibility: "hidden",
                opacity: 0,
                position: "absolute",
              }}
            >
              {title}
            </h1>
            <Stack mt={0} gap={"26px"} maxWidth="28rem">
              <RichText
                field={description}
                variant="paragraphMedium"
                textAlign="center"
                px={2}
              />
            </Stack>
          </Stack>
          <ActionButton
            id="bottom-btn"
            onClick={() => setOpen(false)}
            // onTouchStart={() => setOpen(false)}
            onMouseEnter={() => setPointer({ type: "default" })}
            onMouseLeave={() => setPointer(false)}
            css={(theme) => ({
              position: "absolute",
              bottom: "60px",
              [mq.down.sm({ theme })]: {
                bottom: "24px",
              },
              left: "50%",
              transform: "translateX(-50%)",
              pointerEvents: "auto",
            })}
          />
        </RenderLayer>
      </Wrapper>
    </SageProvider>
  );
}

const Wrapper = styled(Box)`
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.primary.lighter};
`;

const ClipPath = styled.clipPath`
  --scale: 1;
  --x: 0;
  --y: 0;
  --rotate: 0deg;
  ${(props) => ({ ...props.config })};
  transform: translate3d(var(--x), var(--y), 0) scale(var(--scale))
    rotate(var(--rotate));
`;
